import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import cx from 'classnames';
import Arrow from '../../images/Arrow';
import { gsap, Power1 } from 'gsap';

const Footer = ({ data, isIntroCompleted, location }) => {
  const footerElem = useRef();
  const arrowLink = useRef();
  const { footer } = data.themeOptions;
  const mobileBreakpoint = 1024;
  const isContactPage = location.pathname === '/contact/';
  const [isFolded, setIsFolded] = useState(!isContactPage)
  const [hasScrollEnded, setHasScrollEnded] = useState(false);

  // Default to link to frontpage, if not go back to previous page
  // TODO use useHistory instead of location.pathname
  const previousUrl = location.state ? location.state.from : '/';

  let lastScrollUp = window.scrollY;

  const onScroll = () => {
    const offset = window.innerWidth <= mobileBreakpoint ? 80 : 0;

    if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - offset)) {
      return setHasScrollEnded(true);
    }

    handleScrollUp();
  }

  useEffect(() => {
    if (isIntroCompleted) {
      footerElem.current.addEventListener('mouseenter', () => { setHasScrollEnded(true) });
      footerElem.current.addEventListener('mouseleave', () => { setHasScrollEnded(false) });

      window.addEventListener('scroll', onScroll);
      window.addEventListener('touchmove', onScroll);
    }

    arrowAnimation();
  }, [isIntroCompleted, isFolded, window.location.pathname]);

  const flipArrow = () => {
    setIsFolded(!isFolded);
  }

  // Need to make sure that if user tries to scroll up we close footer
  function handleScrollUp() {
    lastScrollUp > window.scrollY ? setHasScrollEnded(false) : lastScrollUp = window.scrollY;
  }

  function arrowAnimation() {
    gsap.fromTo(arrowLink.current, {
      rotate: isFolded ? 0 : 180,
      y: isFolded ? 0 : 20,
      transformOrigin: 'top',
      ease: Power1.easeInOut
    }, {
      y: isFolded ? 20 : 40,
      repeat: -1,
      yoyo: true
    })
  }

  return (
    <footer
      ref={footerElem}
      className={cx('footer', {
        'footer--popup': hasScrollEnded,
        'footer--visible': isIntroCompleted,
        'footer--fullheight': isContactPage && isIntroCompleted,
      })}
    >
      <div className="footer__content">
        <div className="footer__column">
          <div className="footer__link-wrapper">
            <Link onClick={flipArrow} id="footer-link" className="footer__link flex flex-col items-center" to={{pathname: isFolded ? footer.contactLink.uri : previousUrl, state: { from: location.pathname }}}>
              <div ref={arrowLink}>
                <Arrow color="#fff" classes="footer__arrow" />
              </div>
              {footer.contactLabel}
            </Link>
          </div>
          <div className="contact-spawnpoint" />
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  data: PropTypes.object.isRequired,
  isIntroCompleted: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired
}

export default withRouter(Footer);
import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import playIcon from './../../images/play.svg';

const VideoPlayer = ({ videoUrl, posterUrl }) => {
  const video = useRef();
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    const resolved = () => {
      setIsPlaying(!isPlaying);
    }
    if (isPlaying) {
      try {
        video.current.pause().then(resolved);
      } catch {
        return;
      }
    }

    video.current.play().then(resolved).catch(/* fuck */);
  }

  const enterFullscreen = () => {
    if (video.current.requestFullscreen) {
      video.current.requestFullscreen();
    }
    else if (video.current.mozRequestFullScreen) {
      video.current.mozRequestFullScreen();
    }
    else if (video.current.webkitRequestFullScreen) {
      video.current.webkitRequestFullScreen();
    }
    else if (video.current.webkitEnterFullScreen) {
      video.current.webkitEnterFullScreen();
    }
    else if (video.current.msRequestFullscreen) {
      video.current.msRequestFullscreen();
    }
  }

  return (
    <div className="video"
      onMouseEnter={() => {
        togglePlay()
      }}
      onMouseLeave={(event) => {
        togglePlay()
      }}
    >
      <div className="video__wrapper">
        <div className="video__fullscreen">
          <p onClick={() => enterFullscreen()} className="text-lg sm:text-xl hover:cursor-pointer">➝ FULL SCREEN </p>
        </div>
        <video
          id={videoUrl}
          className="video__frame"
          allowFullScreen="allow"
          ref={video}
          playsInline
          
          poster={posterUrl}
          onClick={() => togglePlay()}
          onPause={() => setIsPlaying(false)}
        >
          <source src={videoUrl} />
        </video>
        {
          !isPlaying &&
            <img className="video__play-icon" src={playIcon} alt="Play" onClick={() => togglePlay()} />
        }
      </div>
    </div>
  );
}

VideoPlayer.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  posterUrl: PropTypes.string.isRequired,
}

export default VideoPlayer;
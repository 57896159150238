import React, { useLayoutEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import gsap from 'gsap';
import { GET_CONTACT_CONTENT } from './../gql';
import Arrow from '../../images/Arrow';

const Contact = ({ menus, page, isIntroCompleted, isInitialPage }) => {
  const contactBlock = useRef();
  const overlayRoot = document.querySelector('.contact-spawnpoint');
  const childRoot = document.createElement('div');

  const { data, loading } = isInitialPage
    ? { data: null, loading: false }
    : useQuery(GET_CONTACT_CONTENT, {
      variables: { id: page.databaseId }
    });

  useLayoutEffect(() => {
    if (isIntroCompleted && !loading) {
      overlayRoot.appendChild(childRoot);
      document.body.classList.remove('theme__dark--invert');
      gsap.from(contactBlock.current, { delay: .5, duration: .4, opacity: 0 });

      return () => {
        overlayRoot.removeChild(childRoot);
      }
    }
  }, [isIntroCompleted, loading]);

  const getMenuNode = menuId => {
    const { nodes } = menus;
    const menu = nodes.filter(menu => menu.databaseId === menuId);
    return menu[0].menuItems.nodes;
  }

  const { content } = data ? data.page : page;
  return isIntroCompleted && !loading && (
    createPortal(
      <section ref={contactBlock}>
        <div
          className="pt-12 text-white text-lg lg:text-2xl text-center"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        <nav>
          <ul className="list sm:pt-12 text-center flex flex-col items-center">
            {getMenuNode(3).map((item, index) =>
              <li className="flex" key={index}>
                <Arrow rotation="90" size="2" color="#fff" classes="section_cta-arrow"></Arrow>
                <a
                  className="text-white no-underline hover:underline text-2xl"
                  href={item.path}
                  rel="noopener noreferrer"
                  target={item.target ? item.target : '_self'}
                >
                  {item.label}
                </a>
              </li>
            )}
          </ul>
        </nav>
      </section>,
      overlayRoot
    )
  );
}

Contact.propTypes = {
  isIntroCompleted: PropTypes.bool.isRequired,
  isInitialPage: PropTypes.bool.isRequired,
  page: PropTypes.object.isRequired,
  menus: PropTypes.object.isRequired,
}

export default Contact;
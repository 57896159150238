import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { GET_INITIAL_CONTENT } from './gql';
import Logotype from './components/Logotype';
import Footer from './components/Footer';
import Navigation from './components/Navigation';
import Routes from './views/Routes';

const App = () => {
  const [isIntroCompleted, setIsIntroCompleted] = useState(false);
  const { data, loading } = useQuery(GET_INITIAL_CONTENT, {
    variables: {
      pageId: window.location.pathname,
      primaryMenuId: 2,
      contactMenuId: 3,
    }
  });

  useEffect(() => {
    if (isIntroCompleted) {
      document.body.classList.remove('locked');
    }
  }, [isIntroCompleted]);

  return (
    <BrowserRouter>
      <Logotype
        loading={loading}
        data={data}
        setIsIntroCompleted={setIsIntroCompleted}
        isIntroCompleted={isIntroCompleted}
      />
      {loading ? null : (
        <>
          <Routes data={data} isIntroCompleted={isIntroCompleted} />
          <Footer data={data} isIntroCompleted={isIntroCompleted} />
          <div className="overlay-spawnpoint" />
          <Navigation data={data} isIntroCompleted={isIntroCompleted} />
        </>
      )}
    </BrowserRouter>
  );
}

export default App;
import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import gsap from 'gsap';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin';
import { GET_SETTINGS } from './../gql';

const BurgerButton = ({ onClick, isClosing }) => {
  const delay = .2;
  const duration = .2;
  const button = useRef();
  gsap.registerPlugin(DrawSVGPlugin);
  const { data } = useQuery(GET_SETTINGS);
  const { showBurgerMenu } = data;

  useLayoutEffect(() => {
    const closeMenuStrokes = button.current.querySelectorAll('.button__close .button__stroke');
    const openMenuStrokes = button.current.querySelectorAll('.button__open .button__stroke');
    openMenuStrokes.forEach(stroke => gsap.from(stroke, {delay, duration, drawSVG: '100%' }));
    closeMenuStrokes.forEach(stroke => gsap.set(stroke, {drawSVG: '50% 50%', opacity: 0 }));
  }, []);

  useLayoutEffect(() => {
    const openMenuStrokes = button.current.querySelectorAll('.button__open .button__stroke');
    const closeMenuStrokes = button.current.querySelectorAll('.button__close .button__stroke');

    if (isClosing) {
      openMenuStrokes.forEach(stroke => gsap.to(stroke, { delay, duration, drawSVG: '100%' }));
      closeMenuStrokes.forEach(stroke => {
        gsap.to(stroke, { delay, duration, drawSVG: '50% 50%' });
        gsap.set(stroke, {opacity: 0});
      });     
    } else {
      openMenuStrokes.forEach(stroke => gsap.to(stroke, { duration, drawSVG: '50% 50%' }));
      closeMenuStrokes.forEach(stroke => {
        gsap.set(stroke, {opacity: 1});
        gsap.to(stroke, { delay, duration, drawSVG: '100%' });
      }); 
    }
  }, [isClosing]);

  useLayoutEffect(() => {
    const openMenuStrokes = button.current.querySelectorAll('.button__open .button__stroke');

    if (showBurgerMenu) {
      button.current.classList.remove('hidden');
      openMenuStrokes.forEach(stroke => gsap.to(stroke, { delay, duration, drawSVG: '100%' }));
    } else {
      openMenuStrokes.forEach(stroke => gsap.to(stroke, { duration, drawSVG: '50% 50%' }).then(() => button.current.classList.add('hidden')));
    }
  }, [showBurgerMenu]);

  return (
    <button className="button button--burger" onClick={onClick} ref={button}>
      <svg viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g className="button__open">
          <path className="button__stroke" d="M0,6 C100,6,100,6,100,6" />
          <path className="button__stroke" d="M0,50 C100,50,100,50,100,50" />
          <path className="button__stroke" d="M0,94 C100,94,100,94,100,94" />
        </g>
        <g className="button__close">
          <path className="button__stroke" d="M0,0 C100,100,100,100,100,100" />
          <path className="button__stroke" d="M100,0 C0,100,0,100,0,100" />
        </g>
      </svg>
    </button>
  )
}

BurgerButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isClosing: PropTypes.bool.isRequired,
}

export default BurgerButton;
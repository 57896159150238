import gql from 'graphql-tag';
import { memberDetails } from './shared';

const GET_MEMBER_CONTENT = gql`
  query getMemberContent(
    $slug: ID!
  ) {
    member(id: $slug, idType: URI) {
      ${memberDetails}
    }
  }
`;

export {
  GET_MEMBER_CONTENT
};
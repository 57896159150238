import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import gsap from 'gsap';
import BurgerButton from './BurgerButton';
import Overlay from './Overlay';

const Navigation = ({ data, isIntroCompleted, location }) => {
  const [isClosing, setIsClosing] = useState(true);
  const [isNavOpen, setIsNavOpen] = useState(false);

  useEffect(() => {
    if (isNavOpen) {
      gsap.from('.navigation__item', { delay: .4, duration: .2, stagger: -.1, opacity: 0 });
    }
  }, [isNavOpen]);

  useEffect(() => {
    const isContactPage = location.pathname === '/contact/';
    const overlayBlock = document.querySelector('.overlay-spawnpoint');

    if (isContactPage) {
      overlayBlock.classList.add('overlay-spawnpoint--opaque');
      return () => overlayBlock.classList.remove('overlay-spawnpoint--opaque');
    }
  }, [location]);

  const closingDelay = () => {
    const delay = 1000;

    return new Promise(resolve => {
      setTimeout(() => {
        resolve(false);
      }, delay);
    });
  }

  const toggleOverlay = async isNavOpen => {
    setIsClosing(isNavOpen ? true : false);
    const state = isNavOpen ? await closingDelay() : true;
    setIsNavOpen(state);
  }

  const getMenuNode = menuId => {
    const { nodes } = data.menus;
    const menu = nodes.filter(menu => menu.databaseId === menuId);
    return menu[0].menuItems.nodes;
  }

  return isIntroCompleted ? (
    <>
      <BurgerButton
        isClosing={isClosing}
        onClick={() => toggleOverlay(isNavOpen)}
      />
      {isNavOpen &&
        <Overlay isClosing={isClosing}>
          <nav className="navigation">
            <ul className="navigation__list">
              {getMenuNode(2).map((item, index) =>
                <li className="navigation__item" key={index}>
                  <Link className="navigation__link" onClick={() => toggleOverlay(isNavOpen)} to={{pathname: item.path, state: { fromNavigation: true }}} >
                    {item.label}
                  </Link>
                </li>
              )}
            </ul>
          </nav>
        </Overlay>
      }
    </>
  ) : null;
}

Navigation.propTypes = {
  isIntroCompleted: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default withRouter(Navigation);
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useParams, withRouter } from 'react-router-dom';
import { Transition } from 'react-transition-group';
import gsap from 'gsap';
import { GET_CASE_CONTENT, GET_SETTINGS } from './../gql';
import CloseButton from './CloseButton';
import VideoPlayer from './VideoPlayer';
import Arrow from '../../images/Arrow';

const CaseSlide = ({ show, isIntroCompleted, history }) => {
  const [showScrollArrow, setShowScrollArrow] = useState(true)
  const overlayRoot = document.querySelector('.overlay-spawnpoint');
  const childRoot = document.createElement('div');
  const lgScreenBreakpoint = 1024;
  const slidePosX = lgScreenBreakpoint
    ? window.innerWidth
    : window.innerWidth / 2;
  const { slugId } = useParams();
  const { data, client } = useQuery(GET_CASE_CONTENT, {
    variables: { slug: `/cases/${slugId}` }
  });

  const onScroll = e => {
    if (e.target.scrollTop > 50) {
      setShowScrollArrow(false)
    } else {
      setShowScrollArrow(true)
    }
  };

  useEffect(() => {
    if (show && isIntroCompleted) {
      overlayRoot.appendChild(childRoot);
      overlayRoot.classList.add('overlay-spawnpoint--opaque');
      toggleBurgerButton(false);

      return () => {
        overlayRoot.removeChild(childRoot);
        overlayRoot.classList.remove('overlay-spawnpoint--opaque');
        toggleBurgerButton(true);
      }
    }
  }, [show, isIntroCompleted]);

  const toggleBurgerButton = state => {
    client.cache.writeQuery({
      query: GET_SETTINGS,
      data: {
        showBurgerMenu: state,
      }
    });
  }

  const exitSlide = () => {
    history.push('/work');
  }

  return isIntroCompleted && (
    <Transition
      unmountOnExit
      in={show && isIntroCompleted}
      timeout={1000}
      onEnter={node => gsap.set(node, { x: slidePosX })}
      addEndListener={(node, done) => {
        gsap.to(node, {
          duration: .2,
          x: show ? 0 : slidePosX,
          onComplete: done
        })
      }}
    >
      <>
        {createPortal(
          <div className="overlay">
            <div className="overlay__container">
              <section onScroll={onScroll} className="case-slide">
                {data &&
                  <>
                    <div className="case-slide__profile">
                      <div className="grid lg:grid-cols-6 gap-8">
                        <div className="lg:col-span-2">
                          <figure className="case-slide__figure">
                            {data.case && data.case.featuredImage &&
                              <img
                                className="case-slide__profile-image"
                                src={data.case.featuredImage.node.sourceUrl}
                                alt={data.case.featuredImage.node.altText || data.case.featuredImage.node.title}
                                width={data.case.featuredImage.node.mediaDetails.width}
                                height={data.case.featuredImage.node.mediaDetails.height}
                              />
                            }
                          </figure>
                        </div>
                        <div className="lg:col-span-4">
                          <div className="case-slide__description">
                            <hgroup className="case-slide__hgroup">
                              <h1 className="case-slide__title">
                                {data.case && data.case.title}
                              </h1>
                              <h2 className="case-slide__title">
                                {data.case && data.case.caseDetails.agency}
                              </h2>
                            </hgroup>
                            <div className="case-slide__text" dangerouslySetInnerHTML={{ __html: data.case && data.case.content }} />
                          </div>
                        </div>
                      </div>
                    </div>
                    {data.case && data.case.caseDetails.caseGallery &&
                      data.case.caseDetails.caseGallery.map((showcase, index) =>
                        <div className="case-tile" key={index}>
                          {showcase.videoUrl ? (
                            <VideoPlayer
                              videoUrl={showcase.videoUrl}
                              posterUrl={showcase.poster.sourceUrl}
                            />
                          ) : (
                            <figure className="case-tile__figure">
                              {showcase.poster &&
                                  <img
                                    className="case-tile__image"
                                    loading="lazy"
                                    width={showcase.poster.mediaDetails.width}
                                    height={showcase.poster.mediaDetails.height}
                                    srcSet={showcase.poster.srcSet}
                                    alt={showcase.poster.altText || showcase.poster.title}
                                  />
                              }
                            </figure>
                          )}
                          <div className="case-tile__description">
                            <h1 className="case-tile__title">
                              {showcase.title}
                            </h1>
                            <p className="case-tile__text">
                              <span className="case-tile__by-agency">by</span> {showcase.agency}
                            </p>
                          </div>
                        </div>
                      )
                    }
                    {
                      data.case && data.case.caseDetails.caseGallery.length > 1 &&
                      <div
                        className={`case-tile__scroll-arrow ${!showScrollArrow ? 'case-tile__scroll-arrow--fade-out' : ''}`}>
                        <Arrow rotation="180" size="3" color="#fff" classes="section_cta-arrow"></Arrow>
                      </div>
                    }
                  </>
                }
              </section>
              <CloseButton
                invert
                isClosing={show === false}
                onClick={() => exitSlide()}
              />
            </div>
          </div>,
          overlayRoot
        )}
      </>
    </Transition>
  );
}

CaseSlide.propTypes = {
  show: PropTypes.bool.isRequired,
  isIntroCompleted: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
}

export default withRouter(CaseSlide);
import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Route } from 'react-router-dom';
import { GET_WORK_CONTENT } from './../gql';
import CaseTile from './../components/CaseTile';
import CaseSlide from './../components/CaseSlide';

const Work = ({ page, isInitialPage, isIntroCompleted }) => {
  const { data, loading } = isInitialPage
    ? { data: null, loading: false }
    : useQuery(GET_WORK_CONTENT, {
      variables: { id: page.databaseId }
    });

  useLayoutEffect(() => {
    if (!loading && isIntroCompleted) {
      document.body.classList.add('theme__dark--invert');
      //document.body.classList.remove('theme__dark--invert');
    }
  }, [isIntroCompleted, loading]);

  const { workPage } = data ? data.page : page;
  return loading ? null : (
    <article className="deck deck--no-spacing">
      <div className="grid sm:grid-cols-2 gap-0">
        {workPage.cases.map((item, index) =>
          <CaseTile
            key={index}
            index={index}
            data={item.case}
            isIntroCompleted={isIntroCompleted}
          />
        )}
      </div>
      <Route path="/work/:slugId">
        {({ match }) =>
          <CaseSlide
            show={match !== null}
            isIntroCompleted={isIntroCompleted}
          />
        }
      </Route>
    </article>
  );
}

Work.propTypes = {
  page: PropTypes.any,
  isInitialPage: PropTypes.bool.isRequired,
  isIntroCompleted: PropTypes.bool.isRequired
}

export default Work;
import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import gsap from 'gsap';
import { useQuery } from '@apollo/client';
import ScrollTrigger from 'gsap/ScrollTrigger';
import SplitText from 'gsap/SplitText';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { GET_DEFAULT_CONTENT } from './../gql';

const DefaultPage = ({ page, isIntroCompleted, isInitialPage }) => {
  const contentContainer = useRef();
  gsap.registerPlugin(ScrollTrigger, SplitText, ScrollToPlugin);

  const { data, loading } = isInitialPage
    ? { data: null, loading: false }
    : useQuery(GET_DEFAULT_CONTENT, {
      variables: { id: page.databaseId }
    });

  useLayoutEffect(() => {
    if (!loading && isIntroCompleted) {
      const columns = contentContainer.current.querySelectorAll('.column');
      const headers = contentContainer.current.querySelectorAll('.header');

      gsap.set(columns, { opacity: 0 });
      gsap.set(headers, { opacity: 0 });

      setTimeout(() => {
        gsap.set(columns, { opacity: 1 });

        headers.forEach(header => {
          gsap.to(header, { duration: .4, opacity: 1 });
        });

        columns.forEach(column => {
          const paragraphs = column.querySelectorAll('p');

          paragraphs.forEach(paragraph => {
            const splitText = new SplitText(paragraph, { type: 'words' });

            let tl = gsap.timeline({
              scrollTrigger: {
                trigger: paragraph
              }
            });

            tl.from(splitText.words, {
              opacity: 0,
              stagger: .05,
              duration: .2,
            });
          });
        });  
      }, 400);

      document.body.classList.remove('theme__dark--invert');
    }
  }, [isIntroCompleted, loading]);

  const { content, title } = data ? data.page : page;
  return loading ? null : (
    <div className="deck deck--default">
      <div className="container mx-auto lg:w-1/2 max-w-screen-xl px-4 sm:px-8" ref={contentContainer}>
        <h1 className="header mt-0 mb-10 sm:mb-24 uppercase text-4xl sm:text-8xl leading-none">
          {title}
        </h1>
        <div className="text-2xl sm:text-3xl">
          <div className="column">
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

DefaultPage.propTypes = {
  page: PropTypes.any,
  isInitialPage: PropTypes.bool.isRequired,
  isIntroCompleted: PropTypes.bool.isRequired
}

export default DefaultPage;
import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import gsap from 'gsap';
import SplitText from 'gsap/SplitText'

const Logotype = ({
  loading,
  data,
  setIsIntroCompleted,
  isIntroCompleted
}) => {
  const [appendToCorner, setAppendToCorner] = useState(false);
  const isFrontpage = data
    ? data.page?.databaseId === 25
    : false;

  useLayoutEffect(() => {
    if (data) {
      const timeline = gsap.timeline();
      const title = new SplitText('.logotype__title', { type: 'words' });

      if (isFrontpage) {
        setIsIntroCompleted(true);
      }

      timeline.from(title.words, { duration: .1, opacity: 0, y: 20, stagger: .1 });

      timeline.to(title.words, { delay: .75, duration: .25, opacity: 0, y: -20, stagger: .3 }).then(() => {
        if (!isFrontpage) {
          setIsIntroCompleted(true);
        }
        setAppendToCorner(true);
        timeline.to(title.words, { duration: 0, y: 20 });
        timeline.to(title.words, { duration: .1, opacity: 1, y: 0, stagger: .1 });
      });
      
      setAppendToCorner(true);
      timeline.to(title.words, { duration: 0, y: 20 });
      timeline.to(title.words, { duration: .1, opacity: 1, y: 0, stagger: .1 });

    }
  }, [data]);

  const renderLogotype = (state, isFrontpage) => {
    return (
      <div className={cx('logotype', {
        'logotype--frontpage': isFrontpage,
        'logotype--corner': state
      })}>
        {state ? (
          <div className="logotype__title">
            <a
              href="/"
              className="logotype__link"
              dangerouslySetInnerHTML={{ __html: loading ? null : data.themeOptions.introSettings.logotype }}
            />
          </div>
        ) : (
          <div
            className="logotype__title"
            dangerouslySetInnerHTML={{ __html: loading ? null : data.themeOptions.introSettings.logotype }}
          />
        )}
      </div>
    )
  }

  if (isFrontpage) return renderLogotype(appendToCorner, isFrontpage);

  return renderLogotype(isIntroCompleted);
}

Logotype.propTypes = {
  data: PropTypes.any,
  loading: PropTypes.bool.isRequired,
  setIsIntroCompleted: PropTypes.func.isRequired,
  isIntroCompleted: PropTypes.bool.isRequired,
}

export default Logotype;
import gql from 'graphql-tag';
import { agencyDetails } from './shared';

const GET_AGENCY_CONTENT = gql`
  query getAgencyContent(
    $slug: ID!
  ) {
    agency(id: $slug, idType: URI) {
      ${agencyDetails}
    }
  }
`;

export { GET_AGENCY_CONTENT }
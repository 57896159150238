import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Route } from 'react-router-dom';
import gsap from 'gsap';
import { GET_AGENCIES_CONTENT } from './../gql';
import MemberProfile from '../components/MemberAgencyProfile';
import MemberSlide from '../components/MemberAgencySlide';

const Agencies = ({ page, isIntroCompleted, isInitialPage }) => {
  const agenciesContainer = useRef();

  const { data, loading } = isInitialPage
    ? { data: null, loading: false }
    : useQuery(GET_AGENCIES_CONTENT, {
      variables: { id: page.databaseId }
    });

  useLayoutEffect(() => {
    if (isIntroCompleted && !loading) {
      document.body.classList.remove('theme__dark--invert');
      const timeline = gsap.timeline({ delay: .4 });
      const agencies = agenciesContainer.current.querySelectorAll('.list__member');
      agencies.forEach(agency => timeline.from(agency, { duration: .2, stagger: .1, opacity: 0 }));
    }
  }, [isIntroCompleted, loading]);

  const { agenciesPage } = data ? data.page : page;
  return loading ? null : (
    <section className="deck deck--agencies">
      <div className="container mx-auto max-w-screen-xl px-4 sm:px-8 pb-16 sm:pb-64">
        <ul className="list" ref={agenciesContainer}>
          {agenciesPage.roster && agenciesPage.roster.map((item, index) =>
            <li className="list__member" key={index}>
              <MemberProfile isAgency={true} data={item.agency} />
            </li>
          )}
        </ul>
        <Route path="/agencies/:slugId">
          {({ match }) =>
            <MemberSlide
              isAgency={true}
              show={match !== null}
              isIntroCompleted={isIntroCompleted}
            />
          }
        </Route>
      </div>
    </section>
  );
}

Agencies.propTypes = {
  page: PropTypes.any,
  isInitialPage: PropTypes.bool.isRequired,
  isIntroCompleted: PropTypes.bool.isRequired
}

export default Agencies;
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import cx from 'classnames';
import { useQuery } from '@apollo/client';
import {Link, useParams, withRouter} from 'react-router-dom';
import { Transition } from 'react-transition-group';
import gsap from 'gsap';
import Vimeo from '@u-wave/react-vimeo';
import { GET_MEMBER_CONTENT, GET_AGENCY_CONTENT, GET_SETTINGS } from './../gql';
import CloseButton from './CloseButton';
import VideoPlayer from './VideoPlayer';
import Arrow from '../../images/Arrow';

const MemberAgencySlide = ({ show, isIntroCompleted, history, isAgency }) => {
  const [showScrollArrow, setShowScrollArrow] = useState(true);
  const overlayRoot = document.querySelector('.overlay-spawnpoint');
  const childRoot = document.createElement('div');
  const lgScreenBreakpoint = 1024;
  const slidePosX = lgScreenBreakpoint
    ? window.innerWidth
    : window.innerWidth / 2;
  const { slugId } = useParams();
  const { data, client } = isAgency
    ? useQuery(GET_AGENCY_CONTENT, {
      variables: { slug: `/agencies/${slugId}` }
    })
    : useQuery(GET_MEMBER_CONTENT, {
      variables: { slug: `/members/${slugId}` }
    });

  const onScroll = e => {
    if (e.target.scrollTop > 50) {
      setShowScrollArrow(false)
    } else {
      setShowScrollArrow(true)
    }
  };

  useEffect(() => {
    if (show && isIntroCompleted) {
      overlayRoot.appendChild(childRoot);
      overlayRoot.classList.add('overlay-spawnpoint--opaque');
      toggleBurgerButton(false);

      return () => {
        overlayRoot.removeChild(childRoot);
        overlayRoot.classList.remove('overlay-spawnpoint--opaque');
        toggleBurgerButton(true);
      }
    }
  }, [show, isIntroCompleted]);

  const toggleBurgerButton = state => {
    client.cache.writeQuery({
      query: GET_SETTINGS,
      data: {
        showBurgerMenu: state,
      }
    });
  }

  const exitSlide = () => {
    if (isAgency) {
      history.push('/agencies');
    } else {
      history.push('/members');
    }
  }

  return isIntroCompleted && (
    <Transition
      unmountOnExit
      in={show && isIntroCompleted}
      timeout={1000}
      onEnter={node => gsap.set(node, { x: slidePosX })}
      addEndListener={(node, done) => {
        gsap.to(node, {
          duration: .2,
          x: show ? 0 : slidePosX,
          onComplete: done
        })
      }}
    >
      <>
        {
          createPortal(
            <div className="overlay">
              <div className={cx('overlay__container', {
                'overlay__container--black': !isAgency,
                'overlay__container--white': isAgency
              })}>
                <section onScroll={onScroll} className="member-agency-slide">
                  {
                    data && data.agency &&
                    <>
                      <div className={cx('member-agency-slide__profile', {
                        'member-agency-slide__profile--white': !isAgency,
                        'member-agency-slide__profile--black': isAgency
                      })}>
                        <div className="grid lg:grid-cols-6 gap-8">
                          <div className="lg:col-span-4">
                            <div className="member-agency-slide__agency-description">
                              <hgroup className="member-agency-slide__hgroup">
                                <h1 className="member-agency-slide__title">
                                  {data.agency.title}
                                </h1>
                              </hgroup>
                              <div className="member-agency-slide__text">
                                <p>{data.agency.agencyDetails.about}</p>
                              </div>
                            </div>
                          </div>
                          <div className="lg:col-span-2">
                            {/*<div className="member-agency-slide__logo">
                              <figure className="member-agency-slide__atn-logo">
                                <img
                                  className="member-agency-slide__profile-image"
                                  src={PlaceholderStar}
                                  alt={data.agency.title}
                                />
                              </figure>
                            </div>*/}
                          </div>
                        </div>
                        <div className="grid lg:grid-cols-6 gap-8">
                          <div className="lg:col-span-4">
                            { data.agency.agencyDetails.keyPeople && <h2 className="member-agency-slide__title">People</h2> }
                            {
                              data.agency.agencyDetails.keyPeople && data.agency.agencyDetails.keyPeople.member.map((member, index) =>
                                <div key={index} className="member-agency-slide__people">
                                  <Link to={`/members/${member.slug}`}><p>{member.memberDetails.name}</p></Link>
                                </div>
                              )
                            }
                          </div>
                        </div>
                        {
                          data.agency.agencyDetails.work && <h2 className="member-agency-slide__title">Work</h2>
                        }
                        <div className="member-agency-slide__work">
                          {
                            data.agency.agencyDetails.work &&
                            data.agency.agencyDetails.work.map((work, index) => {
                              // Todo: remove this shit code when vimeo links are fixed
                              const temp = work.vimeoVideoUrl.split('/');
                              if (temp.indexOf('player.vimeo.com') === -1) {
                                const id = temp[temp.length-1].length > 1 ? temp[temp.length-1] : [temp.length-2];
                                const iFrameId = `${data.agency.title}-${index}-iframe`;
                                return (
                                  <div className="case-tile case-tile--iframe" key={index} style={{ backgroundImage: `url(${work.poster.sourceUrl})` }}>
                                    <Vimeo
                                      showByline={false}
                                      controls={false}
                                      loop={true}
                                      showPortrait={false}
                                      background={true}
                                      autoplay={true}
                                      autopause={false}
                                      height="100%"
                                      responsive={true}
                                      id={iFrameId}
                                      video={id}
                                      color={'rgba(255, 255, 255, 0.0)'}
                                    />
                                  </div>
                                )
                              } else {
                                return (
                                  <div className="case-tile" key={index}>
                                    <VideoPlayer videoUrl={work.vimeoVideoUrl}
                                      posterUrl={work.poster ? work.poster.sourceUrl : 'https://atn.fieldtest.xyz/wp-content/uploads/2020/07/dummy-case.png'}/>
                                  </div>
                                );
                              }
                            })
                          }
                        </div>
                        <div className="grid lg:grid-cols-6 gap-8">
                          <div className="lg:col-span-8">
                            <p><i>{data.agency.agencyDetails.services}</i></p>
                          </div>
                        </div>
                        <div className="grid lg:grid-cols-6 gap-8">
                          <div className="lg:col-span-8">
                            {
                              data.agency.agencyDetails.clients && data.agency.agencyDetails.clients.length > 0 &&
                                <h2 className="member-agency-slide__title">Clients</h2>
                            }
                            {
                              data.agency.agencyDetails.clients && data.agency.agencyDetails.clients.map((item) =>
                                <p key={item.client} className="member-agency-slide__client">{item.client}</p>
                              )
                            }
                          </div>
                        </div>
                        <div className="grid lg:grid-cols-6 gap-8">
                          <div className="lg:col-span-4">
                            {
                              data.agency.agencyDetails.contacts && data.agency.agencyDetails.contacts.length > 0 &&
                              <h2 className="member-agency-slide__title">Contact</h2>
                            }
                            {
                              data.agency.agencyDetails.contacts && data.agency.agencyDetails.contacts.map((item) =>
                                <div key={item.contact} className="member-agency-slide__people"><p>{item.contact}</p></div>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  }
                  {
                    data && data.member &&
                    <>
                      <div className="member-agency-slide__profile">
                        <div className="grid lg:grid-cols-6 gap-8">
                          <div className="lg:col-span-2">
                            {
                              data.member.featuredImage &&
                                <figure className="member-agency-slide__figure">
                                  <img
                                    className="member-agency-slide__profile-image"
                                    src={data.member.featuredImage.node.sourceUrl}
                                    alt={data.member.featuredImage.node.altText || data.member.featuredImage.node.title}
                                    width={data.member.featuredImage.node.mediaDetails.width}
                                    height={data.member.featuredImage.node.mediaDetails.height}
                                  />
                                </figure>
                            }
                            {
                              data.member.memberDetails.photo && data.member.memberDetails.photo.sourceUrl &&
                              <img
                                loading="lazy"
                                className="member-agency-slide__profile-image"
                                src={data.member.memberDetails.photo.sourceUrl}
                                alt={data.member.memberDetails.name}
                              />
                            }
                          </div>
                          <div className="lg:col-span-4">
                            <div className="member-agency-slide__description">
                              <hgroup className="member-agency-slide__hgroup">
                                <h1 className="member-agency-slide__title">
                                  {data.member.title}
                                </h1>
                                <h2 className="member-agency-slide__title">
                                  {data.member.memberDetails.agency}
                                </h2>
                              </hgroup>
                              {
                                data.member.memberDetails.title &&
                                <h3 className="member-agency-slide__text">{data.member.memberDetails.title}</h3>
                              }
                              {
                                data.member.content &&
                                <div className="member-agency-slide__text" dangerouslySetInnerHTML={{ __html: data.member.content }} />
                              }
                              {
                                data.member.memberDetails.biography &&
                                <div className="member-agency-slide__text">{data.member.memberDetails.biography}</div>
                              }
                              {
                                data.member.memberDetails.email &&
                                <a rel="noopener noreferrer" href={`mailto:${data.member.memberDetails.email}`} className="block my-2 lowercase member-agency-slide__text">{data.member.memberDetails.email}</a>
                              }
                              {
                                data.member.memberDetails.phone &&
                                <a rel="noopener noreferrer" href={`tel:${data.member.memberDetails.phone}`} className="block my-2 member-agency-slide__text">{data.member.memberDetails.phone}</a>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      {
                        data.member && data.member.memberDetails.showcaseGallery &&
                          data.member.memberDetails.showcaseGallery.map((showcase, index) => {
                            return (
                              <div className="case-tile" key={index}>
                                {
                                  showcase.videoUrl ? (
                                    <VideoPlayer
                                      videoUrl={showcase.videoUrl}
                                      posterUrl={showcase.poster.sourceUrl}
                                    />
                                  ) : (
                                    <figure className="case-tile__figure">
                                      {
                                        showcase.poster && showcase.poster.srcSet ? (
                                          <img
                                            className="case-tile__image"
                                            loading="lazy"
                                            width={showcase.poster.mediaDetails.width}
                                            height={showcase.poster.mediaDetails.height}
                                            srcSet={showcase.poster.srcSet}
                                            alt={showcase.poster.altText || showcase.poster.title}
                                          />
                                        ) : (
                                          <img
                                            className="case-tile__image"
                                            loading="lazy"
                                            width={showcase.poster.mediaDetails.width}
                                            height={showcase.poster.mediaDetails.height}
                                            src={showcase.poster.sourceUrl}
                                            alt={showcase.poster.altText || showcase.poster.title}
                                          />
                                        )
                                      }
                                    </figure>
                                  )}
                                <div className="case-tile__description">
                                  <h1 className="case-tile__title">
                                    {showcase.title}
                                  </h1>
                                  <p className="case-tile__text">
                                    <span className="case-tile__by-agency">by</span> {showcase.agency}
                                  </p>
                                </div>
                              </div>
                            )
                          })
                      }
                      <div className={`case-tile__scroll-arrow ${!showScrollArrow ? 'case-tile__scroll-arrow--fade-out' : ''}`}>
                        <Arrow rotation="180" size="3" color="#000" classes="section_cta-arrow"></Arrow>
                      </div>
                    </>
                  }
                </section>
                <CloseButton
                  invert
                  isClosing={show === false}
                  onClick={() => exitSlide()}
                />
              </div>
            </div>,
            overlayRoot
          )
        }
      </>
    </Transition>
  );
}

MemberAgencySlide.propTypes = {
  show: PropTypes.bool.isRequired,
  isIntroCompleted: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  isAgency: PropTypes.bool,
}

export default withRouter(MemberAgencySlide);
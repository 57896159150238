import gql from 'graphql-tag';
import { memberDetails, agencyDetails, frontpageSection, caseDetails, howWeWork } from './shared';

const GET_INITIAL_CONTENT = gql`
  query getInitialContent(
    $pageId: ID!
  ) {
    themeOptions {
      introSettings {
        logoCoordinates {
          latitude
          longitude
        }
        logotype
      }
      footer {
        contactLabel
        contactLink {
          ... on Page {
            id
            uri
          }
        }
      }
    }
    pages(first: 100) {
      nodes {
        isFrontPage
        databaseId
        uri
      }
    }
    page(idType: URI, id: $pageId) {
      title
      content
      databaseId
      frontpageSettings {
        introVideo
      }
      frontpageSections {
        sectionPeople {
          vimeoVideoUrl
          ${frontpageSection}
        }
        sectionManifesto {
          quote
          ${frontpageSection}
        }
        sectionStar {
          ${frontpageSection}
        }
        sectionWork {
          vimeoVideoUrl
          quotes {
            quote
          }
          ${frontpageSection}
        }
        sectionMethods {
          ${frontpageSection}
        }
        sectionCsr {
          vimeoVideoUrl
          ${frontpageSection}
        }
      }
      agenciesPage {
        roster {
          agency {
            ... on Agency {
              ${agencyDetails}
            }
          }
        }
      }
      membersPage {
        roster {
          member {
            ... on Member {
              ${memberDetails}
            }
          }
        }
      }
      workPage {
        cases {
          case {
            ... on Case {
              ${caseDetails}
            }
          }
        }
      }
      howWeWork {
        ${howWeWork}
      }
    }
    menus {
      nodes {
        databaseId
        menuItems {
          nodes {
            path
            label
            target
          }
        }
      }
    }
  }
`;

const GET_FRONTPAGE_CONTENT = gql`
  query getFrontpageContent(
    $id: ID!
  ) {
    page(id: $id, idType: DATABASE_ID) {
      content
      frontpageSettings {
        introVideo
      }
    }
  }
`;

const GET_AGENCIES_CONTENT = gql`
  query getAgenciesContent(
    $id: ID!
  ) {
    page(id: $id, idType: DATABASE_ID) {
      agenciesPage {
        roster {
          agency {
            ... on Agency {
              ${agencyDetails}
            }
          }
        }
      }
    }
  }
`;

const GET_MEMBERS_CONTENT = gql`
  query getMembersContent(
    $id: ID!
  ) {
    page(id: $id, idType: DATABASE_ID) {
      membersPage {
        roster {
          member {
            ... on Member {
              ${memberDetails}
            }
          }
        }
      }
    }
  }
`;

const GET_WORK_CONTENT = gql`
  query getWorkContent(
    $id: ID!
  ) {
    page(id: $id, idType: DATABASE_ID) {
      workPage {
        cases {
          case {
            ... on Case {
              ${caseDetails}
            }
          }
        }
      }
    }
  }
`;

const GET_DEFAULT_CONTENT = gql`
  query getDefaultContent(
    $id: ID!
  ) {
    page(id: $id, idType: DATABASE_ID) {
      title
      content
    }
  }
`;

const GET_CONTACT_CONTENT = gql`
  query getContactContent(
    $id: ID!
  ) {
    page(id: $id, idType: DATABASE_ID) {
      title
      content
      databaseId
    }
  }
`;

const GET_HOWWEWORK_CONTENT = gql`
  query getAgencyInfoContent(
    $id: ID!
  ) {
    page(id: $id, idType: DATABASE_ID) {
      title
      content
      howWeWork {
        ${howWeWork}
      }
    }
  }
`;

export {
  GET_INITIAL_CONTENT,
  GET_FRONTPAGE_CONTENT,
  GET_AGENCIES_CONTENT,
  GET_MEMBERS_CONTENT,
  GET_WORK_CONTENT,
  GET_DEFAULT_CONTENT,
  GET_CONTACT_CONTENT,
  GET_HOWWEWORK_CONTENT,
};
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import gsap from 'gsap';
import CloseButton from './../components/CloseButton';

const Overlay = ({ children, isClosing, closeOverlay }) => {
  const overlayContainer = useRef();
  const overlayRoot = document.querySelector('.overlay-spawnpoint');
  const childRoot = document.createElement('div');

  useEffect(() => {
    overlayRoot.appendChild(childRoot);

    return () => {
      overlayRoot.removeChild(childRoot);
    }
  }, []);

  useLayoutEffect(() => {
    const mobileBreakpoint = 1024;
    const x = window.innerWidth <= mobileBreakpoint ? window.innerWidth : window.innerWidth / 2;
    const duration = .2;

    if (isClosing) {
      document.body.classList.remove('locked');
      document.querySelector('.overlay-mask').classList.remove('overlay-mask--cliphalf');
      gsap.to(overlayContainer.current, { duration, x, ease: 'ease' });
    } else {
      document.body.classList.add('locked');
      document.querySelector('.overlay-mask').classList.add('overlay-mask--cliphalf');
      gsap.from(overlayContainer.current, { duration, x, ease: 'ease' });
    }
  }, [isClosing]);

  return (
    createPortal(
      <div className="overlay">
        <div className="overlay__container" ref={overlayContainer}>
          {children}
        </div>
        {closeOverlay &&
          <CloseButton onClick={closeOverlay} isClosing={isClosing} invert />
        }
      </div>,
      overlayRoot
    )
  );
}

Overlay.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element
  ]).isRequired,
  isClosing: PropTypes.bool,
  closeOverlay: PropTypes.func
}

export default Overlay;
import React from 'react';
import PropTypes from 'prop-types';

const ArrowUp = ({color, rotation, size, classes}) => {
  const style = {
    transform: `rotate(${rotation}deg)`,
    height: `${size}rem`
  }

  return (
    <div className={classes}>
      <svg fill={color} style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 135 236"><path d="M134.08 78.53l-16.5 13.2-39.36-49.77H77.2v193.23H56.9V41.97h-1.02L16.52 92.49.02 79.29 67.05.58l67.03 77.95z" /></svg>
    </div>
  )
}

ArrowUp.propTypes = {
  color: PropTypes.string,
  rotation: PropTypes.string,
  size: PropTypes.string,
  classes: PropTypes.string
}


export default ArrowUp;
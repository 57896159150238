import React, { useRef, useState, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import gsap from 'gsap';

const CaseTile = ({ data, index, isIntroCompleted }) => {
  const image = useRef();
  const caseDescription = useRef();
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const {
    slug,
    title,
    featuredImage,
    caseDetails,
  } = data;

  useLayoutEffect(() => {
    gsap.set(caseDescription.current, { opacity: 0 });
    gsap.set(image.current, { opacity: 0 });
    const timeline = gsap.timeline({ delay: .2 * index });

    if (isImageLoaded && isIntroCompleted) {
      timeline.to(image.current, { stagger: .1, duration: .2, opacity: 1 });
      timeline.to(caseDescription.current, { delay: .3, stagger: .1, duration: .2, opacity: 1 });
    }
  }, [isImageLoaded, isIntroCompleted]);

  return (
    <Link className="case-tile" to={`/work/${slug}`}>
      <figure className="case-tile__figure">
        {
          featuredImage && featuredImage.node &&
          <img
            className="case-tile__image"
            ref={image}
            loading="lazy"
            width={featuredImage.node.mediaDetails.width}
            height={featuredImage.node.mediaDetails.height}
            srcSet={featuredImage.node.srcSet}
            alt={featuredImage.node.altText || featuredImage.node.title}
            onLoad={() => setIsImageLoaded(true)}
          />
        }
        {
          !featuredImage &&
          <img
            className="case-tile__image"
            ref={image}
            loading="lazy"
            width={caseDetails.caseGallery[0].poster.mediaDetails.width}
            height={caseDetails.caseGallery[0].poster.mediaDetails.height}
            srcSet={caseDetails.caseGallery[0].poster.srcSet}
            alt={caseDetails.caseGallery[0].poster.altText}
            onLoad={() => setIsImageLoaded(true)}
          />
        }
      </figure>
      <div className={'case-tile__description'+' color--'+caseDetails.titleColor} ref={caseDescription}>
        <h1 className="case-tile__title">
          {title}
        </h1>
        <p className="case-tile__text">
          by {caseDetails.agency}
        </p>
      </div>
    </Link>
  );
}

CaseTile.propTypes = {
  data: PropTypes.object.isRequired,
  isIntroCompleted: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
}

export default CaseTile;
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';
import { useQuery } from '@apollo/client';
import cx from 'classnames';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { Link } from 'react-router-dom';
import { GET_FRONTPAGE_CONTENT } from './../gql';
import ScrollText from '../components/ScrollText';
import Arrow from '../../images/Arrow';

const Frontpage = ({ page, isInitialPage }) => {
  gsap.registerPlugin(ScrollTrigger);
  const peopleVideoRef = useRef();
  const starVideoRef = useRef();
  const workVideoRef = useRef();
  const csrVideoRef = useRef();
  const { sectionPeople, sectionManifesto, sectionStar, sectionWork, sectionMethods, sectionCsr } = page.frontpageSections;

  useEffect(() => {
    createVideoScrollTrigger(peopleVideoRef.current, '-50px');
    createVideoScrollTrigger(starVideoRef.current);
    createVideoScrollTrigger(workVideoRef.current);
    createVideoScrollTrigger(csrVideoRef.current);

    gsap.utils.toArray('.section').forEach((section) => {
      ScrollTrigger.create({
        trigger: section,
        start: 'top top',
        pin: true,
        pinSpacing: false,
      });
    });
  })

  const { loading } = isInitialPage
    ? { data: null, loading: false }
    : useQuery(GET_FRONTPAGE_CONTENT, {
      variables: { id: page.databaseId }
    });

  function createVideoScrollTrigger(videoRef, offset = '0') {
    ScrollTrigger.create({
      trigger: videoRef,
      start: offset,
      onEnter: () => videoRef.play(),
      onLeave: () => videoRef.pause(),
      onEnterBack: () => videoRef.play()
    })
  }

  return loading ? null : (
    <>
      <div className="section-container">
        <section className={cx('section sectionPeople', { 'section--darkmode': sectionPeople.darkmode })}>
          <video ref={peopleVideoRef} autoPlay playsInline muted loop className="absolute top-0 left-0 w-full h-full object-cover">
            <source src={sectionPeople.vimeoVideoUrl} />
          </video>
          <div onMouseEnter={() => peopleVideoRef.current.pause()} onMouseLeave={() => peopleVideoRef.current.play()} className="section_cta navigation__link white">
            <Arrow rotation="90" size="3" color="#fff" classes="section_cta-arrow"></Arrow>
            <Link className="text-3xl md:text-4xl" to={sectionPeople.link.url} >
              {sectionPeople.link.title}
            </Link>
          </div>
        </section>
        <section className={cx('section sectionManifesto', { 'section--darkmode': sectionManifesto.darkmode })}>
          <ScrollText text={sectionManifesto.quote} identifier="1" repeat={40}></ScrollText>
          <div className="section_cta navigation__link">
            <Arrow rotation="90" size="3" color="#fff" classes="section_cta-arrow"></Arrow>
            <Link className="text-3xl md:text-4xl" to={sectionManifesto.link.url} >
              { sectionManifesto.link.title}
            </Link>
          </div>        
        </section>
        <section className={cx('section sectionStar', { 'section--darkmode': sectionStar.darkmode })}>
          <video ref={starVideoRef} autoPlay playsInline muted loop className="absolute top-0 left-0 w-full h-full object-cover star-video">
            <source src="https://player.vimeo.com/external/466073974.sd.mp4?s=42ac377a0d0d6490dbf69760ccc541cd267aa017&profile_id=165" />
          </video>
          <div onMouseEnter={() => starVideoRef.current.pause()} onMouseLeave={() => starVideoRef.current.play()} className="section_cta navigation__link">
            <Arrow rotation="90" size="3" color="#000" classes="section_cta-arrow"></Arrow>
            <Link className="text-3xl black md:text-4xlk" to={sectionStar.link.url} >
              {sectionStar.link.title}
            </Link>
          </div>
        </section> 
        <section className={cx('section sectionWork', { 'section--darkmode': sectionWork.darkmode })}>
          <video ref={workVideoRef} autoPlay playsInline muted loop className="absolute top-0 left-0 w-full h-full object-cover">
            <source src={sectionWork.vimeoVideoUrl} />
          </video>
          <ScrollText text={sectionWork.quotes.map(quote => quote.quote).join(' ')} identifier="2" repeat={2}></ScrollText>
          <div className="section_cta navigation__link">
            <Arrow rotation="90" size="3" color="#fff" classes="section_cta-arrow"></Arrow>
            <Link className="text-3xl md:text-4xl" to={sectionWork.link.url} >
              {sectionWork.link.title}
            </Link>
          </div>
        </section>
        <section className={cx('section sectionMethods', { 'section--darkmode': sectionMethods.darkmode })}>
          <h1 className="word-one text-8xl absolute m-0">HOW</h1>
          <h1 className="word-two text-8xl absolute m-0">IT</h1>
          <h1 className="word-three text-8xl absolute m-0">WORKS</h1>
          <div className="section_cta navigation__link">
            <Arrow rotation="90" size="3" color="#000" classes="section_cta-arrow"></Arrow>
            <Link className="black text-3xl md:text-4xl" to={sectionMethods.link.url} >
              {sectionMethods.link.title}
            </Link>
          </div>
        </section>
        <section className={cx('section sectionCsr', { 'section--darkmode': sectionCsr.darkmode })}>
          <video ref={csrVideoRef} autoPlay playsInline muted loop className="absolute top-0 left-0 w-full h-full object-cover">
            <source src={sectionCsr.vimeoVideoUrl} />
          </video>
          <div className="section_cta navigation__link section_cta--center">
            <Arrow rotation="90" size="3" color="#fff" classes="section_cta-arrow"></Arrow>
            <Link className="text-3xl md:text-4xl" to={sectionCsr.link.url} >
              {sectionCsr.link.title}
            </Link>
          </div>
        </section>
      </div>
    </>
  );
}

Frontpage.propTypes = {
  page: PropTypes.any,
  isInitialPage: PropTypes.bool.isRequired,
  isIntroCompleted: PropTypes.bool.isRequired
}

export default Frontpage;
import React, { useLayoutEffect, useRef, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { GET_HOWWEWORK_CONTENT } from './../gql';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import SplitText from 'gsap/SplitText';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import Arrow from './../../images/Arrow'

const HowWeWork = ({ page, isIntroCompleted, isInitialPage }) => {
  const [activeBullet, setActiveBullet] = useState(null)
  const contentContainer = useRef();
  gsap.registerPlugin(ScrollTrigger, SplitText, ScrollToPlugin);

  const { data, loading } = isInitialPage
    ? { data: null, loading: false }
    : useQuery(GET_HOWWEWORK_CONTENT, {
      variables: { id: page.databaseId },
    });

  const { title, content, howWeWork } = data ? data.page : page;

  useLayoutEffect(() => {
    if (!loading && isIntroCompleted) {
      if (howWeWork.darkMode) {
        document.body.classList.remove('theme__dark--invert');
      } else {
        document.body.classList.add('theme__dark--invert');
      }

      const columns = contentContainer.current.querySelectorAll('.column');
      const headers = contentContainer.current.querySelectorAll('.header');

      gsap.set(columns, { opacity: 0 });
      gsap.set(headers, { opacity: 0 });

      setTimeout(() => {
        gsap.set(columns, { opacity: 1 });

        headers.forEach((header) => {
          gsap.to(header, { duration: 0.4, opacity: 1 });
        });

        columns.forEach((column) => {
          const paragraphs = column.querySelectorAll('p');

          paragraphs.forEach((paragraph) => {
            animateParagraph(paragraph)
          });
        });

        animateListItems()
      }, 400);
    }
  }, [isIntroCompleted, loading]);

  useEffect(() => {
    if (activeBullet && !loading && isIntroCompleted) {
      const agencyParagraphs = contentContainer.current.querySelector('.column-right').querySelectorAll('p')
      agencyParagraphs.forEach(paragraph => {
        animateParagraph(paragraph)
      })
    }
  }, [activeBullet])

  const animateListItems = () => {
    const delay = content ? 2 : 0.5
    const timeline = gsap.timeline({ delay });
    const listedAgencies = contentContainer.current.querySelectorAll('.list__agency');
    listedAgencies.forEach(agency => timeline.from(agency, { duration: .2, stagger: .1, opacity: 0 }));
  }

  const animateParagraph = (paragraph) => {
    const splitText = new SplitText(paragraph, { type: 'words' });

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: paragraph
      },
    });

    tl.from(splitText.words, {
      opacity: 0,
      stagger: 0.05,
      duration: 0.2,
    });
  }

  const onBulletSelect = (bullet) => {
    setActiveBullet(bullet)
  }

  return loading ? null : (
    <div className='deck deck--default'>

      <div
        className='container mx-auto max-w-screen-xl px-4 sm:px-8'
        ref={contentContainer}
      >
        <h1 className='header mt-0 mb-10 sm:mb-24 uppercase text-4xl sm:text-8xl leading-none'>
          {title}
        </h1>
        <div className='grid sm:grid-cols-2 sm:gap-40 text-2xl sm:text-3xl'>
          <div className="column column-left">

            <div className='content--no-margin' dangerouslySetInnerHTML={{ __html: content }}></div>
            <ul className={`list-none pl-0 ${!content ? 'space-y-4 sm:space-y-10' : 'space-y-1'}`}>
              {howWeWork.bulletPoints.map((bullet, index) => {
                return (
                  <li className="flex items-start hover:cursor-pointer list__agency" key={index} onClick={() => onBulletSelect(bullet)}>
                    {howWeWork.darkMode ? (
                      <Arrow rotation="90" size="2" color="#fff" classes="section_cta-arrow"></Arrow>
                    ) : (
                      <Arrow rotation="90" size="2" color="#000" classes="section_cta-arrow"></Arrow>
                    )}
                    {bullet.buttonLabel}</li>
                )
              })}
            </ul>
          </div>
          {!activeBullet && (
            <div className='column column-right' dangerouslySetInnerHTML={{ __html: howWeWork.introduction }}></div>
          )}
          {activeBullet && (
            <div className='column column-right' dangerouslySetInnerHTML={{ __html: activeBullet.description }}></div>
          )}
        </div>
      </div>
      <div>
      </div>
    </div>
  );
};

HowWeWork.propTypes = {
  page: PropTypes.any,
  isInitialPage: PropTypes.bool.isRequired,
  isIntroCompleted: PropTypes.bool.isRequired,
};

export default HowWeWork;

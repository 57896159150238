import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import gsap from 'gsap';
import cx from 'classnames';

const CloseButton = ({ onClick, isClosing, invert, delay }) => {
  const button = useRef();

  useLayoutEffect(() => {
    const duration = .2;
    const strokes = button.current.querySelectorAll('.button__stroke');

    if (isClosing) {
      strokes.forEach(stroke => gsap.to(stroke, { duration, drawSVG: '50% 50%' }));
    } else {
      strokes.forEach(stroke => gsap.from(stroke, { delay: delay ? delay : .2, duration, drawSVG: '50% 50%' }));
    }
  }, [isClosing]);

  return (
    <button
      ref={button}
      onClick={onClick}
      className={cx('button', 'button--burger', {
        'button--close': invert
      })}
    >
      <svg viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path className="button__stroke" d="M0,0 C100,100,100,100,100,100" />
          <path className="button__stroke" d="M100,0 C0,100,0,100,0,100" />
        </g>
      </svg>
    </button>
  );
}

CloseButton.propTypes = {
  isClosing: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  invert: PropTypes.bool,
  delay: PropTypes.number,
}

export default CloseButton;
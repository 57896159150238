import gql from 'graphql-tag';

const GET_SETTINGS = gql`
  query getSettings {
    showBurgerMenu @client
    overlayFunc @client
  }
`;

export {
  GET_SETTINGS
};
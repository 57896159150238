import React, { useEffect, useRef } from 'react'
import { gsap, Linear } from 'gsap';
import PropTypes from 'prop-types';

function ScrollText( { text, identifier, repeat }) {
  const refContainer = useRef();
  const tl = gsap.timeline();

  // Simple way to make text scroll across screen continually. 
  // For now we need to hardcode how many times it repeats or browser will lag with long text
  // The animation will pause when out of view and reset on scrollBack so user should not notice text is not infinite
  const repeatedText = `${text} `.repeat(repeat);

  useEffect(() => {
    scrollAnimation();
  })

  function scrollAnimation() {
    // Get width of the text element to be used in the scrolling animation
    const textWidth = refContainer.current.offsetWidth + 300;

    tl.fromTo(refContainer.current, {
      x: '100vw'
    }, {
      scrollTrigger: {
        trigger: `.identifier${identifier}`,
        toggleActions: 'play pause resume reset',
      },
      duration: textWidth / 500,
      x: -textWidth,
      ease: Linear.easeNone,
      repeat: -1
    })
  }

  return (
    <h1 className={`identifier${identifier} scroll-text`} ref={refContainer}> {repeatedText.toUpperCase()}</h1>
  )
}

ScrollText.propTypes = {
  text: PropTypes.string,
  identifier: PropTypes.string,
  repeat: PropTypes.number
}

export default ScrollText;
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import gsap from 'gsap';
import CSSPlugin from 'gsap/CSSPlugin';

const MemberAgencyProfile = ({ data, isAgency }) => {
  gsap.registerPlugin(CSSPlugin);
  const button = useRef();
  const [loadFullImage, setLoadFullImage] = useState(false);

  const visibilityOn = (event, figure) => {
    setLoadFullImage(true);
    let top = event.pageY - window.scrollY;
    
    if (window.innerHeight - top < 300) {
      top -= 300;
    }

    gsap.set(figure, {
      css: {
        left: event.pageX,
        top,
        transform: `rotate(${gsap.utils.random(-10, 10)}deg)`,
        display: 'block',
      }
    });
  }

  const visibilityOff = figure => {
    gsap.set(figure, { css: { display: 'none' } });
  }

  const movingPicture = (event, figure) => {
    let top = event.pageY - window.scrollY;

    if (window.innerHeight - top < 300) {
      top -= 300;

    }
    
    gsap.to(figure, { duration: 0, css: { left: event.pageX, top } });
  }

  const getThumbnail = images => {
    const sourceSet = images.split(',');
    const thumbnail = sourceSet[0];
    const trimWidthValue = thumbnail.split(' ');
    return trimWidthValue[0];
  }

  useEffect(() => {
    const figure = button.current.nextElementSibling;
    button.current.addEventListener('mouseenter', () => visibilityOn(event, figure));
    button.current.addEventListener('mouseleave', () => visibilityOff(figure));
    button.current.addEventListener('mousemove', () => movingPicture(event, figure));
  }, []);

  const route = isAgency ? 'agencies' : 'members';

  return (
    <>
      <Link to={`/${route}/${data.slug}`} className="button button--member" ref={button}>
        {data.title}
      </Link>
      <figure className="member-profile__figure">
        {
          data.featuredImage && data.featuredImage.node.srcSet &&
          <img
            loading="lazy"
            className="member-profile__image"
            srcSet={loadFullImage ? data.featuredImage.node.srcSet : getThumbnail(data.featuredImage.node.srcSet)}
            alt={data.featuredImage.node.altText || data.featuredImage.node.title}
            width={data.featuredImage.node.mediaDetails.width}
            height={data.featuredImage.node.mediaDetails.height}
          />
        }
        {
          data.featuredImage && data.featuredImage.node.sourceUrl &&
          <img
            loading="lazy"
            className="member-profile__image"
            src={data.featuredImage.node.sourceUrl}
            alt={data.featuredImage.node.altText || data.featuredImage.node.title}
            width={data.featuredImage.node.mediaDetails.width}
            height={data.featuredImage.node.mediaDetails.height}
          />
        }
        {
          data.memberDetails && data.memberDetails.photo && data.memberDetails.photo.sourceUrl &&
          <img
            loading="lazy"
            className="member-profile__image"
            src={data.memberDetails.photo.sourceUrl}
            alt={data.memberDetails.name}
          />
        }
        {
          (isAgency && data.agencyDetails.logo) &&
            <figure className="member-profile__figure">
              <img
                loading="lazy"
                className="member-profile__image"
                src={data.agencyDetails.logo.sourceUrl}
                alt={data.title}
              />
            </figure>
        }
      </figure>
    </>
  );
}

MemberAgencyProfile.propTypes = {
  data: PropTypes.object.isRequired,
  isAgency: PropTypes.bool
}

export default MemberAgencyProfile;
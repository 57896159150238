import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import DefaultPage from './DefaultPage';
import Frontpage from './Frontpage';
import Members from './Members';
import Work from './Work';
import Contact from './Contact';
import HowWeWork from './HowWeWork';
import Agencies from './Agencies';

const Routes = ({ data, isIntroCompleted }) => {
  const { menus, pages, page: initialPage } = data;

  const renderRoutes = (page, initialPage) => {
    const isInitialPage = initialPage
      ? page.databaseId === initialPage.databaseId
      : false;


    switch (page.uri) {
    case '/':
      return (
        <Route exact path={page.uri} key={page.databaseId}>
          <Frontpage
            isInitialPage={isInitialPage}
            isIntroCompleted={isIntroCompleted}
            page={isInitialPage ? initialPage : page}
          />
        </Route>
      );
    case '/agencies/':
      return (
        <Route path={page.uri} key={page.databaseId}>
          <Agencies
            isInitialPage={isInitialPage}
            isIntroCompleted={isIntroCompleted}
            page={isInitialPage ? initialPage : page}
          />
        </Route>
      );
    case '/members/':
      return (
        <Route path={page.uri} key={page.databaseId}>
          <Members
            isInitialPage={isInitialPage}
            isIntroCompleted={isIntroCompleted}
            page={isInitialPage ? initialPage : page}
          />
        </Route>
      );
    case '/work/':
      return (
        <Route path={page.uri} key={page.databaseId}>
          <Work
            isInitialPage={isInitialPage}
            isIntroCompleted={isIntroCompleted}
            page={isInitialPage ? initialPage : page}
          />
        </Route>
      );
    case '/contact/':
      return (
        <Route path={page.uri} key={page.databaseId}>
          <Contact
            isInitialPage={isInitialPage}
            isIntroCompleted={isIntroCompleted}
            page={isInitialPage ? initialPage : page}
            menus={menus}
          />
        </Route>
      );
    case '/how-we-work/':
      return (
        <Route path={page.uri} key={page.databaseId}>
          <HowWeWork
            isInitialPage={isInitialPage}
            isIntroCompleted={isIntroCompleted}
            page={isInitialPage ? initialPage : page}
            menus={menus}
          />
        </Route>
      );
    default:
      return (
        <Route path={page.uri} key={page.databaseId}>
          <DefaultPage
            isInitialPage={isInitialPage}
            isIntroCompleted={isIntroCompleted}
            page={isInitialPage ? initialPage : page}
          />
        </Route>
      );
    }
  };

  return (
    <div className='overlay-mask'>
      <Switch>
        {pages.nodes.map((page) => renderRoutes(page, initialPage))}
      </Switch>
    </div>
  );
};

Routes.propTypes = {
  data: PropTypes.object.isRequired,
  isIntroCompleted: PropTypes.bool,
};

export default Routes;

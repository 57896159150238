import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Route } from 'react-router-dom';
import gsap from 'gsap';
import { GET_MEMBERS_CONTENT } from './../gql';
import MemberAgencyProfile from '../components/MemberAgencyProfile';
import MemberAgencySlide from '../components/MemberAgencySlide';

const Members = ({ page, isIntroCompleted }) => {
  const membersContainer = useRef();

  const { data, loading } = useQuery(GET_MEMBERS_CONTENT, { variables: { id: page.databaseId } });

  useLayoutEffect(() => {
    if (isIntroCompleted && !loading) {
      document.body.classList.add('theme__dark--invert');
      const timeline = gsap.timeline({ delay: .4 });
      const members = membersContainer.current.querySelectorAll('.list__member');
      members.forEach(member => timeline.from(member, { duration: .2, stagger: .1, opacity: 0 }));
    }
  }, [isIntroCompleted, loading]);

  const { membersPage } = data ? data.page : page;
  return loading ? null : (
    <section className="deck deck--members">
      <div className="container mx-auto max-w-screen-xl px-4 sm:px-8 pb-16 sm:pb-64">
        <ul className="list" ref={membersContainer}>
          {membersPage.roster && membersPage.roster.map((item, index) =>
            <li className="list__member" key={index}>
              <MemberAgencyProfile data={item.member} />
            </li>
          )}
        </ul>
        <Route path="/members/:slugId">
          {({ match }) =>
            <MemberAgencySlide
              show={match !== null}
              isIntroCompleted={isIntroCompleted}
            />
          }
        </Route>
      </div>
    </section>
  );
}

Members.propTypes = {
  page: PropTypes.any,
  isInitialPage: PropTypes.bool.isRequired,
  isIntroCompleted: PropTypes.bool.isRequired
}

export default Members;
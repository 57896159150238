import gql from 'graphql-tag';
import { caseDetails } from './shared';

const GET_CASE_CONTENT = gql`
  query getCaseContent(
    $slug: ID!
  ) {
    case(id: $slug, idType: URI) {
      ${caseDetails}
    }
  }
`;

export {
  GET_CASE_CONTENT
};
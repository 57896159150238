const memberDetails = `
  title
  content
  slug
  databaseId
  memberDetails {
    agency
    title
    showcaseGallery {
      agency
      title
      videoUrl
      poster {
        altText
        title
        srcSet(size: LARGE)
        sourceUrl(size: LARGE)
        mediaDetails {
          height
          width
        }
      }
    }
    name
    photo {
      id
      sourceUrl(size: MEDIUM)
    }
    phone
    email
    biography
    awards {
      award
    }
  }
  featuredImage {
    node {
      altText
      title
      sourceUrl(size: MEDIUM)
      srcSet(size: THUMBNAIL)
      mediaDetails {
        height
        width
      }
    }
  }
`;

const frontpageSection = `
  link {
    target
    title
    url
  }
  darkmode
  order
`;

const caseDetails = `
  caseDetails {
    agency
    titleColor
    caseGallery {
      agency
      title
      videoUrl
      poster {
        altText
        mediaDetails {
          height
          width
        }
        title
        sourceUrl(size: LARGE)
        srcSet
      }
    }
  }
  title
  content
  databaseId
  slug
  featuredImage {
    node {
      altText
      sourceUrl(size: LARGE)
      srcSet
      title
      mediaDetails {
        height
        width
      }
    }
  }
`;

const agencyDetails = `
  title
  content
  slug
  databaseId
  agencyDetails {
    logo {
      id
      sourceUrl(size: MEDIUM)
    }
    name
    about
    keyPeople {
      member {
        ... on Member {
          ${memberDetails}
        }
      }
    }
    contacts {
      contact
    }
    services
    clients {
      client
    }
    work {
      vimeoVideoUrl
      poster {
        altText
        title
        srcSet(size: LARGE)
        sourceUrl(size: LARGE)
        mediaDetails {
          height
          width
        }
      }
    }
  }
  featuredImage {
    node {
      altText
      title
      sourceUrl(size: MEDIUM)
      srcSet(size: THUMBNAIL)
      mediaDetails {
        height
        width
      }
    }
  }
`;

const howWeWork = `
  introduction
  darkMode
  bulletPoints {
    buttonLabel
    description
  }
`;

export {
  caseDetails,
  frontpageSection,
  memberDetails,
  agencyDetails,
  howWeWork,
}